exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-demo-requested-jsx": () => import("./../../../src/pages/demo-requested.jsx" /* webpackChunkName: "component---src-pages-demo-requested-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-more-jsx": () => import("./../../../src/pages/learn-more.jsx" /* webpackChunkName: "component---src-pages-learn-more-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-use-cases-jsx": () => import("./../../../src/pages/use-cases.jsx" /* webpackChunkName: "component---src-pages-use-cases-jsx" */)
}

